import request from '@/utils/request'

const api = {
  aimeList: '/aime/list',
  bindAime: '/aime/bind',
  unbindAime: '/aime/unbind',
  banAimeList: '/aime/ban/list',
  banAime: '/aime/ban/ban',
  unbanAime: '/aime/ban/unban',
  convertFelica: '/aime/convert'
}

export default api

export function getAimeList (parameter) {
  return request({
    url: api.aimeList,
    method: 'get',
    params: parameter
  })
}

export function bindAime (parameter) {
  return request({
    url: api.bindAime,
    method: 'post',
    data: parameter
  })
}

export function unbindAime (parameter) {
  return request({
    url: api.unbindAime,
    method: 'post',
    data: parameter
  })
}

export function getBanAimeList (parameter) {
  return request({
    url: api.banAimeList,
    method: 'get',
    params: parameter
  })
}

export function banAime (parameter) {
  return request({
    url: api.banAime,
    method: 'post',
    data: parameter
  })
}

export function unbanAime (parameter) {
  return request({
    url: api.unbanAime,
    method: 'post',
    data: parameter
  })
}

export function convertFelica (parameter) {
  return request({
    url: api.convertFelica,
    method: 'get',
    params: parameter
  })
}
