<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="Access Code">
              <a-input v-model="queryParam.accessCode" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => this.queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="loadData"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="你确定要删除吗?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleUnban(record.accessCode)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <aime-ban-form
      ref="banModal"
      :visible="banFormVisible"
      :loading="confirmLoading"
      :model="formModel"
      @cancel="handleCancel"
      @ok="handleBanSave"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { banAime, getBanAimeList, unbanAime } from '@/api/aime'
import AimeBanForm from '@/views/aime/modules/AimeBanForm'
export default {
  name: 'AimeBanList',
  components: {
    AimeBanForm,
    STable
  },
  data () {
    return {
      banFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {},
      loadData: parameter => {
        return getBanAimeList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: 'Access Code',
          dataIndex: 'accessCode',
          sorter: true
        },
        {
          title: '封禁时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.banFormVisible = true
    },
    handleCancel () {
      this.banFormVisible = false
    },
    handleBanSave () {
      const form = this.$refs.banModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          banAime(values).then(() => {
            this.banFormVisible = false
            form.resetFields()
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUnban (accessCode) {
      unbanAime({ accessCode }).then(() => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped>

</style>
